import React, { useState, useRef, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { constants, useServices, useTranslation } from 'cng-web-lib'
import EhblCloseMsgApiUrls from 'src/apiUrls/EhblCloseMsgApiUrls'
import EhblCloseMsgKeys from 'src/constants/locale/key/EhblCloseMsg'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment-timezone'
import pathMap from 'src/paths/pathMap'
import Table from 'src/components/aciacehighway/Table'
import { FileForUserGetPartyId,GetFilterByUserIdCriteria,FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { getStatusMetadata } from '../common'
import ConfirmDialog from 'src/views/common/ConfirmDialog'
import UserProfileApiUrls from "src/apiUrls/UserProfileApiUrls";
import { format } from 'date-fns'

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: { EQUAL, LIKE, IN, BETWEEN }
} = constants

function TablePage(props) {
  const { history, location, showNotification } = props
  const tableRef = useRef(null)
  const { deleteRecord, securedSendRequest } = useServices()

  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.EHBL_CLOSE_MSG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const [sbPartyIdList, setSBPartyIdList] = useState([])
  const [sbPartyIdFetched, setSBPartyIdFetched] = useState(false)

  let filter = GetFilterByUserIdCriteria("EHBL")

  function makeTranslatedTextsObject() {
    let ehblCloseMsg = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.TITLE
    )

    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: ehblCloseMsg
      }
    )

    let previousCcn = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PREVIOUS_CCN
    )

    let primaryCcn = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PRIMARY_CCN
    )

    let uniqueRefNo = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UNIQUE_REFERENCE_NO
    )

    let manifestId = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.MANIFEST_ID
    )

    let status = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.STATUS
    )

    let createdDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.CREATED_DATE
    )

    let updatedDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UPDATED_DATE
    )

    let submittedBy = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMITTED_BY
    )

    let submittedDate = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMITTED_DATE
    )

    let saveButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SAVE_BUTTON
    )

    let editButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.EDIT_BUTTON
    )

    let viewButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.VIEW_BUTTON
    )

    let cloneButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.CLONE_BUTTON
    )

    let deleteButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.DELETE_BUTTON
    )

    let updateButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.UPDATE_BUTTON
    )

    let submitButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.SUBMIT_BUTTON
    )

    let editSentRecord = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.EDIT_SENT_RECORDS
    )

    let printButton = translate(
      Namespace.EHBL_CLOSE_MSG,
      EhblCloseMsgKeys.PRINT_BUTTON
    )
    
    return {
      ehblCloseMsg,
      tableTitle,
      previousCcn,
      primaryCcn,
      uniqueRefNo,
      manifestId,
      status,
      createdDate,
      updatedDate,
      submittedBy,
      submittedDate,
      saveButton,
      editButton,
      viewButton,
      cloneButton,
      deleteButton,
      updateButton,
      submitButton,
      editSentRecord,
      printButton
    }
  }

  const [confirmDialog, setConfirmDialog] = useState({ open: false, closeMessage: null })

  const columns = [
    {
      field: "prevCcn",
      sortKey: "prevCcn",
      title: translatedTextsObject.previousCcn,
    },
    {
      field: "primaryCcn",
      sortKey: "primaryCcn",
      title: translatedTextsObject.primaryCcn,
    },
    {
      field: "uniqueRefNo",
      sortKey: "uniqueRefNo",
      title: translatedTextsObject.uniqueRefNo,
    },
    {
      field: "status",
      sortKey: "status",
      title: translatedTextsObject.status,
      render: (rowData) => {
        const { color, label } = getStatusMetadata(rowData.status)

        return <Typography style={{ color }}>{label}</Typography>
      }
    },
    {
      field: "manifestId",
      sortKey: "manifestId",
      title: translatedTextsObject.manifestId,
    },
    {
      field: "updatedDate",
      sortKey: "updatedDate",
      title: translatedTextsObject.updatedDate,
      render: (rowData) =>
        rowData.updatedDate && moment(rowData.updatedDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')

    },
    {
      field: "createdDate",
      sortKey: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')

    }
  ]

  const filters = [
    {
      label: translatedTextsObject.previousCcn,
      type: 'textfield',
      name: 'prevCcn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.primaryCcn,
      type: 'textfield',
      name: 'primaryCcn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.uniqueRefNo,
      type: 'textfield',
      name: 'uniqueRefNo',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'DR',
          filterValue: { value: 'DR' }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'AC',
          filterValue: { value: 'AC' }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'ST',
          filterValue: { value: 'ST' }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'RJ',
          filterValue: { value: 'RJ' }
        },
        {
          fullWidth: true,
          label: 'Modification Rejected',
          value: 'MR',
          filterValue: { value: 'MR' }
        },
        {
          fullWidth: true,
          label: 'Cancel Accepted',
          value: 'CL',
          filterValue: { value: 'CL' }
        },
        {
          fullWidth: true,
          label: 'Cancel Rejected',
          value: 'CR',
          filterValue: { value: 'CR' }
        },
        {
          fullWidth: true,
          label: 'Status Notification',
          value: 'SN',
          filterValue: { value: 'SN' }
        }
      ]
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.updatedDate,
      type: 'daterange',
      name: 'updatedDate',
      operatorType: BETWEEN
    }
  ]

  useEffect(()=>{
    let loginId = "";
    let userPreference = localStorage.getItem("userPreference");
    let fileForUserDetails = sessionStorage.getItem("fileForUserDetails");
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      setSBPartyIdList([])
      setSBPartyIdFetched(true);
    } else {
      let userPreferenceJSON = JSON.parse(userPreference);
      loginId = userPreferenceJSON.tprUserDetail.userProfile.loginId;
    }
    console.log("loginId: " + JSON.stringify(loginId));

    if (loginId !== "") {
      function onSuccess(response) {
        setSBPartyIdList(response.data)
        setSBPartyIdFetched(true);
        console.log("sbPartyIdList: " + JSON.stringify(sbPartyIdList));
      }

      function onError(error) {
        console.log("sbPartyIdList Error: " + JSON.stringify(error));
      }

      function onComplete() { }
      const config = {}

      securedSendRequest.execute('POST', UserProfileApiUrls.SB_PARTYID_LIST, { loginId }, onSuccess, onError, onComplete, config, null);

    }
  },[])

  function handleDeleteCloseMessage() {
    if (confirmDialog.closeMessage) {
      deleteRecord.execute(
        EhblCloseMsgApiUrls.DELETE,
        confirmDialog.closeMessage,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, closeMessage: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong while deleting record.')
        }
      )
    }
  }

  function handlePrintCloseMessage(closeMsgs){
    function onSuccess(response) {
      let blob = new Blob([response.data]);

      let first = "EHBL_CLMSG_";
      let curDate = format(new Date(), 'yyyyMMddHHmmss');

      let filename  = first + curDate+".pdf";
      if(closeMsgs.length>1){
        filename = first + curDate+".zip";
      }

      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    }

    function onError(error) {
      console.error(error);
    }
  
    function onComplete(){
    }

    const config = {
      responseType: 'blob'
    }

    let prevCcnList = closeMsgs.map((x)=> x.prevCcn);

    let fileForUserDetails = FileForUserGetUserDetails();
      
    const data = {
      prevCcnList: prevCcnList,
      fileForUserId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserId : null,
      fileForUserLoginId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserLoginId : null,
      fileForUserPartyId: fileForUserDetails != null && fileForUserDetails != undefined ? fileForUserDetails.fileForUserPartyId : null
    }
    securedSendRequest.execute('POST',EhblCloseMsgApiUrls.PRINT_CLOSE_MESSAGE,data, onSuccess, onError, onComplete,config,null);  
  
  }

  return (
    <>
    {sbPartyIdFetched && (
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.EHBL_CLOSE_MSG_ADD_VIEW)
            },
            label: 'Create Record'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: EhblCloseMsgApiUrls.EXPORT }}
        fetch={{ url: EhblCloseMsgApiUrls.GET }}
        fetchFilters={[
         { field: 'partyId', operator: IN, value: sbPartyIdList.length > 0 ? sbPartyIdList : FileForUserGetPartyId() },
         ...filter
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          if (rowData.status === 'ST') {
            history.push(`${location.pathname}/view/${rowData.id}`)
          }
          else {
            history.push(`${location.pathname}/edit/${rowData.id}`)
          }
        }}
        rowActions={[
          {
            disabled: (rowData) => rowData.status === 'ST',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) => history.push(`${location.pathname}/edit/${rowData.id}`),
            tooltip: (rowData) =>
              rowData.status === 'ST' ? 'Cannot edit Sent records' : null
          },
          {
            disabled: (rowData) => rowData.status !== 'DR',
            label: translatedTextsObject.deleteButton,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            onClick: (rowData) => setConfirmDialog({ open: true, closeMessage: rowData })
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'eye']} />,
            label: translatedTextsObject.viewButton,
            onClick: (rowData) => history.push(`${location.pathname}/view/${rowData.id}`),
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'print']} />,
            label: translatedTextsObject.printButton,
            onClick: (rowData) => {
              let list = []
              list.push(rowData)
              handlePrintCloseMessage(list)
            }
          },
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
      />
    )}
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, manifest: null })}
        confirmDialog={handleDeleteCloseMessage}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
    </>
  )
}

export default TablePage
